const UahCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#0071F2" fillOpacity="0.2" />
    <path
      d="M18 16.4932C17.7395 17.5014 17.0958 18.3416 16.069 19.0137C15.0575 19.6712 13.8391 20 12.4138 20C10.9119 20 9.70115 19.6639 8.78161 18.9918C7.86207 18.3196 7.4023 17.4429 7.4023 16.3616C7.4023 15.7479 7.68582 15.0977 8.25287 14.411H6V12.8767H9.58621L11.977 11.1233H6V9.58904H13.5172C14.2375 8.90228 14.5977 8.28858 14.5977 7.74795C14.5977 7.29498 14.3678 6.90776 13.908 6.5863C13.4483 6.26484 12.7816 6.10411 11.908 6.10411C10.4215 6.10411 9.27203 6.72511 8.45977 7.96712L7.10345 6.03836C8.3908 4.67945 10.0153 4 11.977 4C13.341 4 14.4828 4.32877 15.4023 4.9863C16.3372 5.64384 16.8046 6.48402 16.8046 7.50685C16.8046 8.20822 16.5057 8.90228 15.908 9.58904H17.5862V11.1233H14.6207L12.1379 12.8767H17.5862V14.411H10.5287C10.023 14.9516 9.77011 15.5434 9.77011 16.1863C9.77011 16.7269 10.023 17.1653 10.5287 17.5014C11.0345 17.8374 11.6015 18.0055 12.2299 18.0055C14.3908 18.0055 15.6782 17.0119 16.092 15.0247L18 16.4932Z"
      fill="#0071F2"
    />
  </svg>
);

export default UahCircle;
