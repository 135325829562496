// core
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import { getTrustWallet } from "../../store/modules/trust-wallet/services";
import { selectTrustWalletData } from "../../store/modules/trust-wallet/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import UsageSlides from "../../components/features/UsageSlides";
import AboutToken from "../../components/features/AboutToken";
import NetContracts from "../../components/features/NetContracts";
import MarketServices from "../../components/features/MarketServices";
import TrustWalletIcons from "./TrustWalletIcons";
import TrustWalletBanner from "./TrustWalletBanner";
import PricePool from "../../components/features/PricePool";

// styles
import "./index.scss";

import AOS from "aos";

const TrustWallet = () => {
  // store
  const dispatch = useDispatch();
  const trustWalletData = useSelector(selectTrustWalletData);
  const variables = useSelector(selectVariables);

  useEffect(() => {
    dispatch(getTrustWallet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trustWalletData.isSuccess && variables.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
      // console.log("blocks", blocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustWalletData.isSuccess, variables.isSuccess]);
  console.log("trustWalletData", trustWalletData.data);

  // @ts-ignore
  return (
    <div className="trust-wallet">
      {trustWalletData.isSuccess && variables.isSuccess ? (
        <>
          <div className="trust-wallet__oveflow">
            <TrustWalletBanner data={trustWalletData.data.banner} />
          </div>
          <PricePool data={trustWalletData.data.prizes} />
          <TrustWalletIcons data={trustWalletData.data.reasons} />
          <div className="trust-wallet__oveflow">
            <MarketServices
              generalData={trustWalletData.data.services}
              className="trust-wallet__market-services"
            />
          </div>
          <NetContracts
            generalData={trustWalletData.data.contracts}
            className="trust-wallet__net-contracts"
          />
          <div className="trust-wallet__about">
            <AboutToken
              data={trustWalletData.data.aboutToken}
              variables={variables.data}
            />
          </div>

          <UsageSlides generalData={trustWalletData.data.usageSlides} />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default TrustWallet;
