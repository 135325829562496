// core
import React from "react";
import { useWindowSize } from "react-use";
// store

// components
import KoloFlowImg from "./../../../assets/images/kolo-flow.png";
import WeldRateArrow from "../../../components/icons/WeldRateArrow";
import WeldRateArrowMob from "../../../components/icons/WeldRateArrowMob";
import AppButton from "../../../components/atoms/Button";

// styles
import "./index.scss";
import { MEDIA } from "../../../utils/constants";

const WeldFlow = ({ data, variables }) => {
  // store
  const { width } = useWindowSize();

  return (
    <div className="kolo-flow">
      <div className="app__wrapper">
        <div className="kolo__title">{data.title}</div>
        <div className="kolo-flow__grid">
          <div
            className="kolo-flow__info"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="kolo-flow__info-profit">
              <p className="kolo-flow__info-profit__num">
                {data.image_title_part_1}
              </p>
              <p className="kolo-flow__info-profit__text">
                {data.image_title_part_2}
              </p>
            </div>
            <img src={data.image || KoloFlowImg} alt="rate" />
          </div>
          <div className="kolo-flow__list">
            {data.steps.map((item, index) => (
              <div
                key={index}
                className="kolo-flow__card"
                style={{ marginLeft: (width > 1024 ? 94 : 56) * index + "px" }}
                data-aos="fade-up"
                data-aos-offset={width > 1024 ? 200 : 0}
                data-aos-delay={width > 1024 ? (index + 1) * 200 + 800 : 0}
              >
                <div className="kolo-flow__card-head">
                  <div className="kolo-flow__card-img">
                    <img src={item.icon} />
                  </div>
                  <span>{item.title}</span>
                  <div className="kolo-flow__card-num">0{index + 1}</div>
                </div>
                <div
                  className="kolo-flow__card-text"
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />

                {index + 1 !== 3 ? (
                  <>
                    {width > MEDIA.M1024 ? (
                      <WeldRateArrow className="kolo-flow__card-arrow" />
                    ) : (
                      <WeldRateArrowMob className="kolo-flow__card-arrow" />
                    )}
                  </>
                ) : (
                  <>
                    {width > MEDIA.M768 && variables.weldmoney_link?.value && (
                      <AppButton
                        className="app-button--fill-3 kolo-flow__card-btn"
                        href={variables.weldmoney_link.value}
                      >
                        {data.button_text}
                      </AppButton>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          {width <= MEDIA.M768 && variables.weldmoney_link?.value && (
            <AppButton
              className="app-button--fill-3 kolo-flow__card-btn"
              href={variables.weldmoney_link.value}
            >
              {data.button_text}
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldFlow;
