// core
import React from "react";

// store

// components

import AppButton from "../../../components/atoms/Button";
import WeldCardImg from "../../../assets/images/koloBanner.png";
import WeldCardSub1Img from "./../../../assets/images/kolo-card-sub-1.png";
import WeldCardSub2Img from "./../../../assets/images/kolo-card-sub-2.png";
import WeldBannerArrowDown from "../../../components/icons/WeldBannerArrowDown";
import WeldBannerArrowUp from "../../../components/icons/WeldBannerArrowUp";

// styles
import "./index.scss";

const WeldBanner = ({ data, variables }) => {
  // store
  return (
    <div className="kolo-banner">
      <div className="app__wrapper">
        <div className="kolo-banner__grid">
          <div
            className="kolo-banner__content"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <h1 className="weld__title">{data.title}</h1>
            <p className="kolo-banner__subtitle kolo-banner__subtitle--1">
              {data.description}
            </p>
            <>
              {variables.weldmoney_link?.value && (
                <div className="kolo-banner__actions">
                  <AppButton
                    className="app-button--fill-3 kolo-banner__btn"
                    href={variables.weldmoney_link.value}
                    target="_blank"
                  >
                    {data.button1_text}
                  </AppButton>
                  <AppButton
                    className="app-button--outline kolo-banner__btn txt-blue"
                    href={variables.weldmoney_link.value}
                    target="_blank"
                  >
                    {data.button2_text}
                  </AppButton>
                </div>
              )}
            </>
          </div>
          <div className="kolo-banner__imgs">
            <img
              src={WeldCardSub1Img}
              className="kolo-banner__subimg-1"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1800"
            />
            <WeldBannerArrowDown
              className="kolo-banner__arrow-down"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1600"
            />
            <img
              src={data.image || WeldCardImg}
              className="kolo-banner__img"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1400"
            />
            <img
              src={WeldCardSub2Img}
              className="kolo-banner__subimg-2"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1800"
            />
            <WeldBannerArrowUp
              className="kolo-banner__arrow-up"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1600"
            />

            {data.image_description && (
              <div
                className="weld-banner__note"
                data-aos="fade"
                data-aos-delay="1000"
                dangerouslySetInnerHTML={{
                  __html:
                    data.image_description +
                    `<a target='_blanck' href="${data.link_in_text}">${
                      " " + data.text_url
                    }</a>`,
                }}
              />
            )}
          </div>

          {/*{width <= MEDIA.M1024 && (*/}
          {/*  <div*/}
          {/*    className="kolo-banner__content app__aos-mobile-disable"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-delay="900"*/}
          {/*  >*/}
          {/*    {variables.weldmoney_link?.value && (*/}
          {/*      <div className="kolo-banner__actions">*/}
          {/*        <AppButton*/}
          {/*          className="app-button--fill-3 kolo-banner__btn"*/}
          {/*          href={variables.weldmoney_link.value}*/}
          {/*          target="_blank"*/}
          {/*        >*/}
          {/*          {t("actions.getBonus")}*/}
          {/*        </AppButton>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*    <div*/}
          {/*      className="kolo-banner__text"*/}
          {/*      dangerouslySetInnerHTML={{*/}
          {/*        __html: data.description,*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};

export default WeldBanner;
