const TIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7432 12.6012V12.5994C13.6456 12.6066 13.1423 12.6372 12.0197 12.6372C11.1233 12.6372 10.4923 12.6102 10.2704 12.5994V12.6021C6.81983 12.4483 4.24432 11.8395 4.24432 11.111C4.24432 10.3835 6.81983 9.77461 10.2704 9.61812V11.996C10.4958 12.0121 11.1419 12.0508 12.0348 12.0508C13.106 12.0508 13.6429 12.0058 13.7432 11.9969V9.61992C17.1867 9.7755 19.756 10.3844 19.756 11.111C19.756 11.8395 17.1867 12.4465 13.7432 12.6012ZM13.7432 9.3726V7.24479H18.5481V4H5.46551V7.24479H10.2704V9.3717C6.36543 9.55337 3.42871 10.3376 3.42871 11.2765C3.42871 12.2154 6.36543 12.9987 10.2704 13.1813V20H13.7432V13.1795C17.6419 12.9978 20.5716 12.2145 20.5716 11.2765C20.5716 10.3385 17.6419 9.55517 13.7432 9.3726Z"
      fill="white"
    />
  </svg>
);

export default TIcon;
