// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AOS from "aos";
import { useWindowSize } from "react-use";

// store
import { getKoloBlocks } from "../../store/modules/kolo/services";
import { getWeldBlocks } from "../../store/modules/weld/services";
import { getGeneralData } from "../../store/modules/general-data/services";
import { getBlocks } from "../../store/modules/blocks/services";
import { selectKoloBlocks } from "../../store/modules/kolo/selectors";
import { selectWeldBlocks } from "../../store/modules/weld/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
import { selectGeneralData } from "../../store/modules/general-data/selectors";
import { selectBlocks } from "../../store/modules/blocks/selectors";
// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import WeldExamples from "./WeldExamples";
import WeldCTA from "./WeldCTA";
import WeldFlow from "./WeldFlow";
import WeldBanner from "./WeldBanner";
import AboutToken from "../../components/features/AboutToken";
import HowToStartUse from "../../components/features/HowToStartUse";

// styles
import "./index.scss";
import { MEDIA } from "../../utils/constants";

const WellCard = () => {
  // store
  const dispatch = useDispatch();
  const koloBlocks = useSelector(selectKoloBlocks);
  const weldBlocks = useSelector(selectWeldBlocks);
  const variables = useSelector(selectVariables);
  const generalData = useSelector(selectGeneralData);
  const blocks = useSelector(selectBlocks);
  const { width } = useWindowSize();

  useEffect(() => {
    dispatch(getKoloBlocks());
    dispatch(getWeldBlocks());
    dispatch(getGeneralData());
    dispatch(getBlocks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      koloBlocks.isSuccess &&
      weldBlocks.isSuccess &&
      variables.isSuccess &&
      generalData.isSuccess &&
      blocks.isSuccess
    ) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    koloBlocks.isSuccess,
    weldBlocks.isSuccess,
    variables.isSuccess,
    generalData.isSuccess,
    blocks.isSuccess,
  ]);
  // @ts-ignore
  return (
    <div className="weld">
      {koloBlocks.isSuccess &&
      weldBlocks.isSuccess &&
      variables.data &&
      generalData.isSuccess &&
      blocks.isSuccess ? (
        <>
          <div className="kolo__oveflow">
            <WeldBanner
              data={koloBlocks.data.banner}
              variables={variables.data}
            />

            {koloBlocks.data.examples.length > 0 && (
              <WeldExamples
                data={koloBlocks.data.exampleBlock}
                referrals={koloBlocks.data.examples}
                variables={variables.data}
              />
            )}

            <div className="app__wrapper kolo-cashback">
              <h3 data-aos="fade-up" data-aos-offset="150" data-aos-delay="200">
                {koloBlocks.data.cashback.title}
              </h3>
              <p data-aos="fade-up" data-aos-offset="150" data-aos-delay="400">
                {koloBlocks.data.cashback.description}
              </p>

              <div
                data-aos="fade-up"
                data-aos-offset="150"
                data-aos-delay="800"
              >
                <img
                  src={
                    width > MEDIA.M480
                      ? koloBlocks.data.cashback.image
                      : koloBlocks.data.cashback.mobile_image
                  }
                  alt="CashBack 2%"
                />
              </div>

              {koloBlocks.data.banner.image_description && (
                <div
                  className="weld-banner__note"
                  data-aos="fade"
                  data-aos-delay="1000"
                  dangerouslySetInnerHTML={{
                    __html:
                      koloBlocks.data.banner.image_description +
                      `<a target='_blanck' href="${
                        koloBlocks.data.banner.link_in_text
                      }">${" " + koloBlocks.data.banner.text_url}</a>`,
                  }}
                />
              )}
            </div>

            <WeldFlow
              data={koloBlocks.data.open}
              variables={variables.data}
              generalData={blocks.data.start_usage}
            />

            <HowToStartUse generalData={blocks.data.start_usage} />
          </div>

          <AboutToken
            className={"kolo__about-token"}
            data={blocks.data.about_token}
            variables={variables.data}
          />

          <WeldCTA
            data={weldBlocks.data.app_install}
            variables={variables.data}
          />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default WellCard;
