const WeldBannerArrowUp = (props) => (
  <svg
    width="153"
    height="70"
    viewBox="0 0 153 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M149.78 0.771818C150.036 0.666938 150.328 0.789 150.433 1.04445L152.142 5.20727C152.247 5.46272 152.125 5.75483 151.869 5.8597C151.614 5.96458 151.322 5.84252 151.217 5.58706L149.698 1.88679L145.997 3.40596C145.742 3.51084 145.45 3.38878 145.345 3.13333C145.24 2.87788 145.362 2.58577 145.618 2.48089L149.78 0.771818ZM0.225493 65.0461C56.5393 77.8481 126.087 57.0922 149.509 1.04157L150.432 1.42714C126.755 58.086 56.6001 78.8875 0.00381476 66.0212L0.225493 65.0461Z"
      fill="#0790F1"
    />
  </svg>
);

export default WeldBannerArrowUp;
