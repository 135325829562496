// core
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { Link as RouterLink } from "react-router-dom";

// utils

// components
import ArrowUpRight from "../../icons/ArrowUpRight";
import HowModal from "../HowModal";
import Document from "../../icons/Document";
import AboutTokenBgImg from "../../../assets/images/about-token-bg.png";
import AboutTokenBgMobImg from "../../../assets/images/about-token-bg-mob.png";

// styles
import "./index.scss";

const AboutToken = ({ data, variables, className }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isActiveModal, setIsActiveModal] = useState(false);

  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };
  return (
    <div id="about" className={"about-token " + (className ? className : "")}>
      <div className="app__wrapper">
        <div className="about-token__grid">
          <div
            className="about-token__box"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2 className="about-token__box-title">{data.title}</h2>

            <div
              className="about-token__box-text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <div className="about-token__box-actions">
              <span
                className="about-token__box-link"
                title={t("blocks.aboutToken.whitepaper")}
                onClick={() => {
                  openModalHandler();
                }}
              >
                {t("actions.scheme")}
              </span>
              <RouterLink
                className="about-token__box-link"
                title={t("blocks.aboutToken.whitepaper")}
                to={`/reserves`}
              >
                {t("actions.aboutReserves")}
                <ArrowUpRight />
              </RouterLink>
            </div>
            <div className="about-token__box-actions">
              {variables.whitepaper_about &&
                variables.whitepaper_about.value && (
                  <a
                    href={variables.whitepaper_about.value}
                    className="about-token__box-link"
                    title={t("blocks.aboutToken.whitepaper")}
                    target="_blank"
                  >
                    <Document />
                    {t("blocks.aboutToken.whitepaper")}
                    <ArrowUpRight />
                  </a>
                )}

              {variables.documents && variables.documents.value && (
                <a
                  href={variables.documents.value}
                  className="about-token__box-link"
                  title={t("blocks.aboutToken.license")}
                  target="_blank"
                >
                  <Document />
                  {t("blocks.aboutToken.license")}
                  <ArrowUpRight />
                </a>
              )}
            </div>
          </div>
          <div className="about-token__globe">
            <img
              src={width > 640 ? AboutTokenBgImg : AboutTokenBgMobImg}
              className="about-token__bg"
            />

            {variables.token_number && (
              <div className="about-token__card">
                <div className="about-token__card-title">
                  {variables.token_number.value}
                </div>

                <div className="about-token__card-text">
                  {t("blocks.aboutToken.numberText")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isActiveModal &&
        ReactDOM.createPortal(
          <HowModal
            isActive={isActiveModal}
            data={data.popup}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default AboutToken;
